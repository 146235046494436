<template>
  <v-row>
    <template v-if="items">
      <v-col v-for="condition in items" :key="condition.id" md="3">
        <v-card class="pa-4 d-flex flex-column justify-center" height="120px">
          <v-img
            position="center"
            class="my-2 mx-auto align-center"
            width="40px"
            :src="require(`@/assets/additional_conditions/${condition.slug}.svg`)"
          />
          <p class="text-center">{{ condition.title }}</p>
        </v-card>
      </v-col>
    </template>
    <template v-if="!items">
      <v-card class="pa-4 d-flex flex-column justify-center" height="120px">
        <v-icon> </v-icon>
        <p class="text-center">{{ $t("No assigned conditions") }}</p>
      </v-card>
    </template>
  </v-row>
</template>

<script>
export default {
  name: "additional-conditions",
  props: ["items"],
  data: () => ({})
};
</script>

<style></style>
