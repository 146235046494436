import { cloneDeep, map, isEqual } from "lodash";
import { cleanSource } from "@/helpers/workingWithObjects";

const groupsList = {
  group_1: ["electrician", "electromechanic"],
  group_2: ["mechanic", "electromechanic"],
  group_3: ["machine_control", "electrician"],
  group_4: ["electrician"]
};

const matrixGroups = [
  {
    continueAfterNull: false,
    group: "group_1",
    answers: [0, null, null, null, null, null, null, null, null, null]
  },
  {
    continueAfterNull: false,
    group: "group_1",
    answers: [1, 0, null, null, null, null, null, null, null, null]
  },
  {
    continueAfterNull: false,
    group: "group_1",
    answers: [1, 1, 0, 0, 1, 1, 0, null, null, null]
  },
  {
    continueAfterNull: false,
    group: "group_1",
    answers: [1, 1, 0, 0, 1, 1, 1, 0, null, null]
  },
  {
    continueAfterNull: false,
    group: "group_2",
    answers: [1, 1, 1, null, null, null, null, null, null, null]
  },
  {
    continueAfterNull: false,
    group: "group_2",
    answers: [1, 1, 0, 1, null, null, null, null, null, null]
  },
  {
    continueAfterNull: false,
    group: "group_2",
    answers: [1, 1, 0, 0, 1, 0, null, null, null, null]
  },
  {
    continueAfterNull: true,
    group: "group_2",
    answers: [1, 1, 0, 0, 0, null, null, null, 0, 0]
  },
  {
    continueAfterNull: false,
    group: "group_3",
    answers: [1, 1, 0, 0, 1, 1, 1, 1, null, null]
  },
  {
    continueAfterNull: true,
    group: "group_3",
    answers: [1, 1, 0, 0, 0, null, null, null, 0, 1]
  },
  {
    continueAfterNull: true,
    group: "group_4",
    answers: [1, 1, 0, 0, 0, null, null, null, 1, null]
  }
];

export default {
  name: "diagnostic-dialog",
  props: ["services"],
  data: () => {
    return {
      isShow: false,
      answersOptions: [],
      groupsList: {},
      questions: [],
      answers: [null],
      disabled: [false],
      result: []
    };
  },
  methods: {
    submit() {
      this.$emit("update:services", this.result);
      this.isShow = false;
    },

    disablePrevQuestions(questionID) {
      if (this.answers[questionID - 1] !== undefined) {
        for (let i = questionID - 1; i >= 0; i--) {
          this.disabled[i] = true;
        }
      }
    },

    /***
     * Get user answers without fake nulls and convert values to numbers
     *
     * @returns {number[]}
     */
    getClearUserAnswers() {
      const answersLength = this.answers.length;
      const cleanAnswers = cleanSource(this.answers);
      const answersWithoutNulls =
        this.answers[answersLength - 1] == null
          ? cleanAnswers.slice(0, answersLength - 1)
          : cleanAnswers;

      return map(answersWithoutNulls, c => {
        return c === null ? null : Number(c);
      });
    },

    /***
     * Append fake null data to show next question
     * @param questionID number
     */
    showNextQuestion(questionID) {
      const answersLength = this.answers.length;
      if (this.questions.length > answersLength && questionID >= answersLength - 1) {
        this.answers.push(null);
      }
    },

    /***
     * Slice fake null data from answers and set cursor to current position
     * @param question number
     */
    hideNextQuestions(question) {
      this.answers = this.answers.slice(0, question + 1);
    },

    /***
     * Find match between possible answers and user answers
     * @param index
     * @returns {boolean}
     */
    possibleAnswersAreEqual(index) {
      const userAnswers = this.getClearUserAnswers();
      const possibleAnswersFull = this.answersOptions[index].answers;
      // slice possible answers to answers length array
      const sliceLength =
        this.answers.length === this.questions.length &&
        this.answers[this.answers.length - 1] != null
          ? this.answers.length
          : this.answers.length - 1;

      const cutArraySizeToCompare = possibleAnswersFull.slice(0, sliceLength);

      // checking for catch possible options
      return isEqual(cutArraySizeToCompare, userAnswers);
    },

    /***
     * Fill answers with nulls if possible question has null answers
     * @param index number
     */
    skipQuestionsWithNulls(index) {
      const nextPossibleValue = this.answersOptions[index].answers[this.answers.length - 1];
      const couldBeNull = this.answersOptions[index].continueAfterNull;

      // Checking if next question null and answers could be null, then fill answers array with nulls
      if (nextPossibleValue == null && couldBeNull) {
        const answersList = this.answersOptions[index]["answers"];
        for (let i = this.answers.length - 1; i <= answersList.length; i++) {
          // no need to push null if in current position it exists
          if (answersList[i] == null && this.answers[i] === undefined) {
            this.answers.push(null);
          }
        }
      }
    },

    /***
     * Set recommendation group
     * @param list object
     */
    setRecommendations(list) {
      if (list != null && Object.hasOwnProperty.call(this.groupsList, list["group"])) {
        this.result = this.groupsList[list["group"]];
      }
    },

    nextQuestion(questionID) {
      this.result = [];
      // Set disable status for checked previous answer
      this.disablePrevQuestions(questionID);
      // for set current position and clear nulls
      this.hideNextQuestions(questionID);
      // push null to show next question
      this.showNextQuestion(questionID);
      const matches = [] as any;
      let possibleList = null;

      // Try to find most possibles matches
      for (const optionId in this.answersOptions) {
        if (this.possibleAnswersAreEqual(optionId)) {
          possibleList = this.answersOptions[optionId];
          this.skipQuestionsWithNulls(optionId);
          matches.push(optionId);
        }
      }

      // if we found most close match, then hide next possible questions and show recommendations
      if (matches.length < 2) {
        this.setRecommendations(possibleList);
        this.hideNextQuestions(questionID);
      }
    },
    setQuestionsList() {
      this.questions = [
        { id: 0, text: this.$t("survey_from_question_1") },
        { id: 1, text: this.$t("survey_from_question_2") },
        { id: 2, text: this.$t("survey_from_question_3") },
        { id: 3, text: this.$t("survey_from_question_4") },
        { id: 4, text: this.$t("survey_from_question_5") },
        { id: 5, text: this.$t("survey_from_question_6") },
        { id: 6, text: this.$t("survey_from_question_7") },
        { id: 7, text: this.$t("survey_from_question_8") },
        { id: 8, text: this.$t("survey_from_question_9") },
        { id: 9, text: this.$t("survey_from_question_10") }
      ];
    }
  },
  computed: {
    servicesResult: {
      get() {
        return this.services;
      },
      set(val) {
        this.$emit("update:services", val);
      }
    }
  },

  watch: {
    isShow() {
      this.disabled = [false];
      this.answers = [null];
      this.result = [];
    }
  },
  beforeMount() {
    this.setQuestionsList();
    this.answersOptions = cloneDeep(matrixGroups);
    this.groupsList = cloneDeep(groupsList);
  }
};
