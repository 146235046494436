import uid from "uid";
import {
  Order as OrderInterface,
  AdditionalInfoItems as AdditionalInfoItemsInterface,
  Urgency as UrgencyInterface,
  Address as AddressInterface
} from "./CreateOrder.interface";

import { uniqBy, cloneDeep, without, includes } from "lodash";
import AddModelDialog from "@/components/AddNewItem.vue";
import DiagnosticDialog from "@/pages/CreateOrder/DiagnosticDialog.vue";
import Steps from "@/components/Steps.vue";
import AdditionalConditions from "@/components/AdditionalConditions.vue";
import {
  getManufacturersList,
  getNodeModelsList,
  getMachineModelsList,
  getLanguagesList,
  getAddConditions,
  getPresignedLink,
  createOrder,
  getDiagnosticTicketsList,
  getAllPaymentCards
} from "@/api";
import { googleGeoParse } from "@/helpers/workingWithObjects";
import { getUserInfo } from "@/auth/auth";

const ADDITIONAL_INFO_TYPES: AdditionalInfoItemsInterface[] = [];
const URGENCY: UrgencyInterface = {
  date_item: "hour",
  date_value: 5
};
const ADDRESS: AddressInterface = {
  object_name: "",
  country: "",
  city: "",
  state: "",
  address_line: "",
  postal_code: "",
  longitude: 0,
  latitude: 0
};

const ORDER: OrderInterface = {
  description: "",
  business_insurance: false,
  object_condition: "",
  repair_type: "machine_repair",
  repair_type_name: null,
  order_type: "diagnostic",
  inspection_type: "current",
  currency: "USD",
  node_model_id: "",
  machine_model_id: "",
  quantity: null,
  specialist: "expert",
  specialisation: "mechanic",
  budget: null,
  language_ids: [],
  additional_conditions: [],
  urgency: URGENCY,
  additional_info_items: ADDITIONAL_INFO_TYPES
};
export default {
  components: { AddModelDialog, Steps, AdditionalConditions, DiagnosticDialog },
  data: () => ({
    needUpdateNodeList: false,
    needUpdateMachineList: false,
    urgencyStartWithinValue: 5,
    urgencyStartWithinType: "hour",
    urgencyStartWithinMin: 5,
    userInfo: getUserInfo() || {},
    financeAccount: null,
    imageModel: null,
    docModel: null,
    orderFormValid: true,
    orderForm: {
      order: ORDER,
      address: ADDRESS
    },
    showOrderErrors: false,
    orderErrors: [],
    languagesItems: [],
    step: 1,
    modelItems: [],
    modelSearchLoading: false,
    modelSearch: null,
    machineItems: [],
    machineSearchLoading: false,
    machineSearch: null,
    nodeItems: [],
    nodeSearchLoading: false,
    nodeSearch: null,
    isShowAddLangField: false,
    newLanguage: "",
    generalViewImg: {
      title: "",
      asset_url: "",
      info_type: "general_view"
    },
    namePlateImg: {
      title: "",
      asset_url: "",
      info_type: "name_plate"
    },
    failedUnitImg: {
      title: "",
      asset_url: "",
      info_type: "failed_unit"
    },
    defaultImgs: [],
    defaultDocs: [],
    newDefaultDoc: {
      title: "",
      asset_url: "",
      info_type: "default_doc",
      file_type: null
    },
    newDefaultImage: {
      title: "",
      asset_url: "",
      info_type: "default_image"
    },
    diagnosticTickets: [],
    additionalConditions: [],
    specialistList: ["expert", "technician"],
    specializationList: {
      expert: [
        "mechanic",
        "electrician",
        "machine_control",
        "electronic",
        "programmer",
        "electromechanic"
      ],
      technician: ["mechanic", "electrician", "electronic", "electromechanic"]
    },
    selectedCondition: null,
    successfullyDialog: false,
    rules: {
      onlyDigits: value => /^\d*$/.test(value) || "Input is invalid",
      fileSize: value => !value || value.size < 21000000 || "File size should be less than 20 MB!"
    }
  }),
  watch: {
    urgencyStartWithinType: function(val) {
      this.orderForm.order.urgency["date_item"] = val;
      this.checkUrgencyStartWithin(this.urgencyStartWithinValue);
    },
    needUpdateNodeList: function(val) {
      if (val) {
        this.getNodesList();
      }
    },
    needUpdateMachineList: function(val) {
      if (val) {
        this.getMachineList();
      }
    }
  },
  computed: {
    isCustomer() {
      return includes(this.userInfo.enabled_profiles, "customer");
    },
    specializations: {
      get() {
        const spcializations = this.specializationList[this.orderForm.order["specialist"]];
        const listOfInputs = [{}];
        for (const specialization in spcializations) {
          const title = spcializations[specialization];
          const formattedList = { title: this.$t(title), value: title };
          if (Number(specialization) <= 0 && listOfInputs.length) {
            listOfInputs.pop();
          }
          listOfInputs.push(formattedList);
        }

        return listOfInputs;
      }
    },
    showDiagnosticAndRepairSelect: {
      get() {
        return this.showSelect("assembly_repair", "repair");
      }
    },
    showServicesSelect: {
      get() {
        return this.showSelect("assembly_repair", "service");
      }
    },
    showSelectReportsList: {
      get() {
        return !this.showSelect("assembly_repair", "service");
      }
    },
    ticketDescription: {
      get() {
        const item = this.diagnosticTickets.find(
          item => item.value === this.orderForm.order["diagnostic_report_id"]
        );
        return item ? item["description"] : "";
      }
    },
    indoor: {
      get() {
        return (
          this.orderForm.order["object_condition"] == "heated" ||
          this.orderForm.order["object_condition"] == "no_heated"
        );
      },
      set() {
        return !(this.orderForm.order["object_condition"] == "outdoor");
      }
    }
  },
  mounted() {
    if (!this.isCustomer) {
      document.location.href = "/";
    }

    this.getFinanceAccount();
    this.getLanguages();
    this.getAdditionalConditions();
    this.getMachineList();
    this.getNodesList();
    this.getDiagnosticTickets();
  },
  methods: {
    showSelect(repairType, orderType) {
      const { order } = this.orderForm;
      const isDesiredRepair = order["repair_type"] === repairType;
      if (isDesiredRepair && order["order_type"] === "diagnostic") {
        order["order_type"] = orderType;
      }
      return isDesiredRepair;
    },
    /**
     * Validation for urgency start within field
     * @param val
     */
    checkUrgencyStartWithin(val) {
      if (this.orderForm.order.urgency["date_item"] === "hour") {
        this.urgencyStartWithinMin = 5;
        if (val < 5) {
          val = 5;
        }
      } else {
        this.urgencyStartWithinMin = 1;
        if (!val || val < 1) {
          val = 1;
        }
      }

      this.urgencyStartWithinValue = val;
      this.orderForm.order.urgency["date_value"] = val;
    },

    /**
     * Get Diagnostic tickets list
     */
    getDiagnosticTickets() {
      getDiagnosticTicketsList()
        .then(r => {
          this.diagnosticTickets = r.data.map(item => {
            return { value: item.id, text: item.title, description: item.info || item.description };
          });
        })
        .catch(err => {
          console.error(err);
        });
    },

    /**
     * Get available languages
     */
    getLanguages() {
      getLanguagesList()
        .then(r => {
          this.languagesItems = r.data
            ? r.data.languages.map(l => {
                return {
                  text: l[1],
                  value: l[0]
                };
              })
            : [];
        })
        .catch(err => {
          console.error(err);
        });
    },

    /**
     * Push selected language to list
     * @param lang
     */
    addNewLanguage(lang) {
      this.orderForm.order["language_ids"].push(lang);
      this.orderForm.order["language_ids"] = uniqBy(this.orderForm.order["language_ids"], "value");
    },

    /**
     * Remove language from list
     * @param lang
     */
    removeLang(lang) {
      this.orderForm.order["language_ids"] = without(this.orderForm.order["language_ids"], lang);
    },

    /**
     * Get additional conditions
     */
    getAdditionalConditions() {
      getAddConditions()
        .then(r => {
          this.additionalConditions = r.data ? r.data : [];
        })
        .catch(err => {
          console.error(err);
        });
    },

    /**
     * Add condition
     */
    addCondition() {
      this.orderForm.order.additional_conditions.push(this.selectedCondition);
      this.orderForm.order.additional_conditions = uniqBy(
        this.orderForm.order.additional_conditions,
        "id"
      );
    },

    /**
     * Remove additional condition
     * @param condition
     */
    removeCondition(condition) {
      this.orderForm.order["additional_conditions"] = without(
        this.orderForm.order["additional_conditions"],
        condition
      );
    },

    /**
     * Get Node list
     */
    getNodesList(item, searchName) {
      searchName = searchName !== undefined ? searchName : this.nodeSearch;
      this.orderForm.order["node_model_id"] = "";
      if (this.nodeSearchLoading) return;
      this.nodeSearchLoading = true;
      getNodeModelsList({ title: searchName })
        .then(r => {
          this.nodeItems = r.data.map((key: any) => {
            return {
              ...key,
              value: key.id,
              text: key.title
            };
          });
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.nodeSearchLoading = false));
    },

    /**
     * Get Machine list
     */
    getMachineList(item, searchName) {
      searchName = searchName !== undefined ? searchName : this.machineSearch;
      this.orderForm.order["machine_model_id"] = "";
      if (this.machineSearchLoading) return;
      this.machineSearchLoading = true;
      getMachineModelsList({ title: searchName })
        .then(r => {
          this.machineItems = r.data.map((key: any) => {
            return {
              ...key,
              value: key.id,
              text: key.title
            };
          });
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.machineSearchLoading = false));
    },

    /**
     * Search
     */
    search(): any {
      this.orderForm.order["node_model_id"] = "";
      if (this.modelSearchLoading) return;
      this.modelSearchLoading = true;

      getManufacturersList({ title: this.modelSearch })
        .then(r => {
          this.modelItems = r.data.map((key: any) => {
            return {
              ...key,
              value: key.id,
              text: key.title
            };
          });
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.modelSearchLoading = false));
    },
    /**
     * Change/upload image for one of selected lists
     * @param file
     * @param obj
     */
    changeImage(file, obj) {
      const fileParams = {
        file_name: uid(),
        file_type: file.name.split(".").pop(),
        file_instance_name: "order_assets"
      };

      const availableTypes = ["image/png", "image/jpeg", "image/bmp"];
      if (!availableTypes.includes(file.type)) {
        this.showOrderErrors = true;
        this.orderErrors = [this.$t("File should be image!")];
        this[obj]["asset_url"] = "";
        return false;
      }

      if (file.size >= 21000000) {
        this.showOrderErrors = true;
        this.orderErrors = [this.$t("File size should be less than 20 MB!")];
        this[obj]["asset_url"] = "";
        return false;
      }

      getPresignedLink(fileParams, file)
        .then(res => {
          this[obj]["asset_url"] = res.url.split("?")[0];
        })
        .catch(e => {
          console.error(e);
        });
    },

    /**
     * Add document for list
     */
    addDoc() {
      const fileParams = {
        file_name: uid(),
        file_type: this.docModel.name.split(".").pop(),
        file_instance_name: "order_assets"
      };
      const supportedTypes = [
        "application/pdf",
        "text/plain",
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/bmp",
        "image/bitmap",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];

      if (supportedTypes.includes(this.docModel.type)) {
        getPresignedLink(fileParams, this.docModel)
          .then(res => {
            this.defaultDocs.push({
              asset_url: res.url.split("?")[0],
              title: this.newDefaultDoc.title,
              info_type: this.newDefaultDoc.info_type,
              file_type: this.docModel.type
            });

            this.newDefaultDoc = {
              asset_url: "",
              title: "",
              info_type: "default_doc",
              file_type: null
            };
          })
          .catch(e => {
            console.error(e);
          });
      } else {
        this.showOrderErrors = true;
        this.orderErrors = [this.$t("File should be image!")];
        return false;
      }
    },

    /**
     * Remove document from list
     * @param index
     */
    removeDefaultDoc(index) {
      this.defaultDocs.splice(index, 1);
    },

    /**
     * Upload and add new image for list
     */
    addImg() {
      const fileParams = {
        file_name: uid(),
        file_type: this.imageModel.name.split(".").pop(),
        file_instance_name: "order_assets"
      };
      getPresignedLink(fileParams, this.imageModel)
        .then(res => {
          this.defaultImgs.push({
            asset_url: res.url.split("?")[0],
            title: this.newDefaultImage.title || this.imageModel.name,
            info_type: this.newDefaultImage.info_type
          });
        })
        .catch(e => {
          console.error(e);
        });
      this.newDefaultImage = { asset_url: "", title: "", info_type: "default_image" };
    },

    /**
     * Remove uploaded image from list
     * @param index
     */
    removeDefaultImage(index) {
      this.defaultImgs.splice(index, 1);
    },

    /**
     * Update address information based on google highlights
     * @param e
     */
    getGeoData(e) {
      const parsedData = googleGeoParse(e);
      this.orderForm.address = {
        country: parsedData.country || "",
        state: parsedData.state || parsedData.city,
        city: parsedData.city || "",
        postal_code: parsedData.zip_code || "",
        longitude: parsedData.longitude || "",
        latitude: parsedData.latitude || "",
        address_line: parsedData.app_address || ""
      };

      this.$refs.address_address_line.$el.focus();
    },

    /**
     * Clear address
     */
    clearGeo() {
      this.orderForm.address = ADDRESS;
    },

    /**
     * Close dialog?
     */
    closeDialog() {
      document.location.href = "/projects/orders/new";
    },

    /**
     * Get finance data
     */
    getFinanceAccount() {
      getAllPaymentCards().then(r => {
        this.financeAccount = r.data !== null && r.data.length ? r.data : null;
      });
    },

    /**
     * Submit form
     */
    submit() {
      this.showOrderErrors = false;
      const orderData = cloneDeep(this.orderForm);
      orderData.order["language_ids"] = this.orderForm.order["language_ids"].map(i => i.value);

      orderData.order["additional_conditions"] = this.orderForm.order["additional_conditions"].map(
        i => i.id
      );
      if (this.namePlateImg["asset_url"]) {
        orderData.order["additional_info_items"].push(this.namePlateImg);
      }
      if (this.generalViewImg["asset_url"]) {
        orderData.order["additional_info_items"].push(this.generalViewImg);
      }
      if (this.failedUnitImg["asset_url"]) {
        orderData.order["additional_info_items"].push(this.failedUnitImg);
      }
      if (this.orderForm.order["order_type"] == "diagnostic") {
        orderData.order["specialist"] = ["expert", "technician"];
      }
      orderData.order["additional_info_items"].push(...this.defaultDocs, ...this.defaultImgs);
      const machineModel = this.orderForm.order["machine_model_id"];
      const nodeModel = this.orderForm.order["node_model_id"];

      if ((machineModel === "" || machineModel < 1) && (nodeModel === "" || nodeModel < 1)) {
        this.showOrderErrors = true;
        this.orderErrors = [this.$t("Please select machine model or node model")];
        return false;
      }

      createOrder(orderData)
        .then(() => {
          this.orderForm = cloneDeep({
            order: ORDER,
            address: ADDRESS
          });
          Object.assign(this.$data, this.$options.data());
          this.successfullyDialog = true;
        })
        .catch(e => {
          this.showOrderErrors = true;
          this.orderErrors = e.response.data.errors[0];
        });
    }
  }
};
