<template>
  <div class="stepper d-flex flex-row">
    <template v-if="disabled">
      <div
        class="step pa-3"
        :class="{ active: step <= activeStep }"
        v-for="step of stepcount"
        v-bind:key="step"
        @click="activeStep = step"
        v-ripple="{ center: true }"
      >
        <p class="step-label">{{ $t(`Step`) }} {{ step }}</p>
        <div class="step-line"></div>
      </div>
    </template>
    <template v-else>
      <div
        class="step pa-3 not-clickable"
        :class="{ active: step <= activeStep }"
        v-for="step of stepcount"
        v-bind:key="step"
        v-ripple="{ center: true }"
      >
        <p class="step-label">{{ $t(`Step`) }} {{ step }}</p>
        <div class="step-line"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "steps",
  props: ["count", "active", "disabled"],
  data: function() {
    return {
      stepcount: Array(+this.count)
        .fill(0)
        .map((e, i) => i + 1)
    };
  },
  computed: {
    activeStep: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("update:active", val);
      }
    }
  }
};
</script>

<style lang="scss">
.stepper {
  .step {
    cursor: pointer;
    border-radius: 12px;
    &-label {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #aeaeae;
    }
    &-line {
      background-color: #dfdfdf;
      width: 48px;
      height: 4px;
      border-radius: 51px;
    }
    &.active .step-line {
      background-color: #005fce;
    }
  }
  .step.not-clickable {
    cursor: default;
    .v-ripple__container {
      display: none;
    }
  }
}
</style>
